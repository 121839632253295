<template>
	<div style="padding: 15px; word-break: break-all;">
		<div v-for="video in DataList">
			<div v-if="buy">
				<video id="myVideo" class="video-js" :poster="video.image" webkit-playsinline="true" x-webkit-airplay="true"  x5-video-player-type="h5" playsinline="true">
					<source :src="video.link">
				</video>

			</div>
			<div v-else class="videobox flexcenter" @click="showbuy=true">
				<img :src="video.image" style="width: 100%;" />
				<div class="player">

				</div>
			</div>
			<div class="videoname">{{video.title}}</div>

			<div class="videoprice">
				<span v-if="video.jifen>0">{{video.jifen}}积分兑换</span>
				<span v-if="video.jifen>0">/</span>
				<span v-if="video.xf_money>0">消费满￥{{video.xf_money}}免费</span>
				<span v-if="video.xf_money>0">/</span>
				<span v-if="video.one_money>0">视频价格￥{{video.one_money}}</span>

			</div>
			<div class="videodesc">{{video.summary}}</div>
			<div v-html="video.contentinfo" class="htmlinfo" style="padding-top: 15px;"></div>
		</div>

		<div class="mask" style="left: 0;background: rgba(0,0,0,0.5);" v-show="showbuy">
			<div style="height: 20%;"></div>
			<div style="width: 70%; padding: 20px; background: #FFFFFF; margin: 0 auto;border-radius: 6px;">
				<div style="color: #000000;">该视频为收费视频，您需要付费观看</div>
				<div style="height: 30px;"></div>
				<div class="btnbuy" v-if="jifen>0" @click="jfDuiHuan">积分兑换({{jifen}}积分)</div>
				<div class="btnbuy" v-if="money>0" @click="videoBuy">立即购买(￥{{money}})</div>

				<div style="text-align: center;" @click="showbuy=false"> 取消</div>
			</div>
		</div>



	</div>
</template>

<script>
	export default {
		data() {
			return {
				DataList: [],
				buy: false,
				viewed: false,
				money: 0,
				jifen: 0,
				showbuy: false
			}
		},
		created() {


		},
		mounted() {
			this.getList()
		},
		methods: {
			initVideo() {
				//初始化视频方法

				let myPlayer = this.$video(document.getElementById('myVideo'), {
					controls: true,
					preload: "auto",
				});

				//$(".vjs-big-play-button").animate({translate3d:"100%,50%,0"})
			},
			getList() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/video/?action=videoinfo&id=" + this.$route.params.id
				}).then(result => {
					let video = result.data.data.video;
					video.xf_money = parseFloat(video.xf_money)
					video.jifen = parseInt(video.jifen)
					video.one_money = parseFloat(video.one_money)
					video.contentinfo = decodeURIComponent(video.contentinfo)
					this.DataList = [video];
					setTimeout(() => {
						$("#loading").hide()
					}, 500)

					if (result.data.data.buy && result.data.data.buy.id) {

						this.buy = true
						this.$nextTick(() => {
							this.initVideo()
						})
						
						

					} else {
						//检查总消费金额
						if (video.xf_money > 0) {
							this.$store.dispatch("GET", {
								url: "/api/video/?action=xiaofei"
							}).then(res => {
								if (res.data.data >= video.xf_money) {
									this.buy = true;
									this.freeWatch();
									this.$nextTick(() => {
										this.initVideo()
									})
									//免费观看
								} else {

									this.jifen = video.jifen;
									this.money = video.one_money;

									//检查积分是否足够

									this.$store.dispatch("GET", {
										url: "/api/wx/?action=me"
									}).then(result => {
										let myjifen = parseInt(result.data.data.rows[0].nowscore);
										if (myjifen < this.jifen) {
											this.jifen = 0;
										}
										this.showbuy = true;
									})
								}

							})
						}else{
							this.jifen = video.jifen;
							this.money = video.one_money;
							
							//检查积分是否足够
							
							this.$store.dispatch("GET", {
								url: "/api/wx/?action=me"
							}).then(result => {
								let myjifen = parseInt(result.data.data.rows[0].nowscore);
								if (myjifen < this.jifen) {
									this.jifen = 0;
								}
								this.showbuy = true;
							})
							
						}


					}


				})
			},
			goPlay(e) {
				this.$route.push("/videoplay/" + e.id)
			},
			freeWatch() {
				this.$store.dispatch("GET", {
					url: "/api/video/?action=freewatch&id=" + this.$route.params.id
				}).then(res => {

				})
			},
			jfDuiHuan() {
				$("#loading").show();
				this.showbuy = false;
				this.$store.dispatch("GET", {
					url: "/api/video/?action=duihuan&id=" + this.$route.params.id
				}).then(res => {
					$("#loading").hide();
					this.getList()
				})
			},
			videoBuy() {
				
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=me"
				}).then(result => {
					let uid  = result.data.data.rows[0].id;
					sessionStorage.setItem("videoorder", uid+"_"+ this.$route.params.id);
					window.location.href = "/pay/";
				})
				
			}
		}
	}
</script>

<style scoped="scoped">
	.htmlinfo image {
		width: 100% !important;
		height: auto !important;
	}

	.videobox {
		width: 100%;
		height: 50vw;
		overflow: hidden;
		position: relative;
	}

	.videoname {
		padding: 10px 0;
		font-size: 14px;
	}

	.videodesc {
		padding: 0;
		margin-bottom: 10px;
		font-size: 12px;
	}

	.videoprice {
		font-size: 12px;
		color: orangered;
		padding-bottom: 10px;
	}

	.player {
		background-image: url(https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1580190330984310.png?s=256_256);
		width: 80px;
		height: 80px;
		position: absolute;
		left: 50%;
		top: 50%;
		background-size: cover;
		transform: translate3d(-40px, -40px, 0);

	}

	.btnbuy {
		width: 80%;
		height: 36px;
		text-align: center;
		line-height: 36px;
		background: #26A1FF;
		color: #FFFFFF;
		margin: 0 auto;
		margin-bottom: 14px;
		border-radius: 4px;
	}

	.video-js {
		width: 100%;
		height: 50vw;
	}
</style>

<style>
	.vjs-big-play-button {
		width: 86px !important;
		height: 45px !important;
		left: 50% !important;
		top: 50% !important;
		transform: translate3d(-50%, -50%, 0) !important;
	}
</style>
